<template>
  <div :class="classList"
       class="row">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'Row',
  props: {
    wrap: {
      type: Boolean,
    },
    align: {
      type: String,
    },
    justify: {
      type: String,
    },
    reverse: {
      type: Boolean,
    },
    full: {
      type: Boolean,
    },
  },
  computed: {
    classList() {
      let list = [];
      if (this.wrap) list = [...list, 'row__wrap'];
      if (this.align) list = [...list, `row__align-${this.align}`];
      if (this.justify) list = [...list, `row__justify-${this.justify}`];
      if (this.reverse) list = [...list, 'row__reverse'];
      if (this.full) list = [...list, 'row__full'];
      return list;
    },
  },
};
</script>
